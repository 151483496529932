import { extendTheme } from "@chakra-ui/react";

const overrides = {
    styles: {
        global: {
            'html, body, #root': {
                height: '100%',
                width: '100%',
                fontFamily: 'Quicksand, sans-serif',
                fontSize: '16px',
                letterSpacing: '1px',
                margin: 0,
                padding: 0,
                minWidth: 0,
                minHeight: 0
            },
            '*::before, *::after': {
                minWidth: 0,
                minHeight: 0
            }
       },
    },
    fonts: {
        body: '"Avenir Next", Open Sans, -apple-system, sans-serif',
    }
}

export const THEME = extendTheme(overrides);