import React from 'react';
import 'tocca';
import { THEME } from "./theme";
import black_logo from './logos/Sarah Quinn (BLACK).png';
import contactPicture from './images/contact.jpg';
import landingPage1 from './images/landingpage/landingpage1.jpg';
import landingPage2 from './images/landingpage/landingpage2.jpg';
import landingPage3 from './images/landingpage/landingpage3.jpg';
import landingPage4 from './images/landingpage/landingpage4.jpg';
import landingPage5 from './images/landingpage/landingpage5.jpg';
import landingPage6 from './images/landingpage/landingpage6.jpg';
import landingPage7 from './images/landingpage/landingpage7.jpg';
import newborn from './images/pricing/newborn.jpg';
import fresh48 from './images/pricing/fresh48.jpg';
import family from './images/pricing/family.jpg';
import couples from './images/pricing/couples.jpg';
import pricingBlurbs from './text/pricing.json';
import aboutMe from './text/aboutme.json';
import insta from './images/instagram.png';
import fb from './images/facebook.png';
import about1 from './images/about/about1.jpg';
import about2 from './images/about/about2.jpg';
import about3 from './images/about/about3.jpg';
import about4 from './images/about/about4.jpg';
import about5 from './images/about/about5.jpg';
import schedulePic1 from './images/schedule/schedule1.jpeg';
import schedulePic2 from './images/schedule/schedule2.jpeg';
import { Box, Button, ChakraProvider, Flex, Grid, Input, List, ListItem, Menu, MenuButton, MenuItem, MenuList, Modal, ModalContent, ModalOverlay, Stack, Textarea, Text, Image, useDisclosure, VStack, FormControl, FormLabel, Radio, RadioGroup, Select } from '@chakra-ui/react';
import { Outlet, Route, Routes, BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import Calendar from 'react-calendar';
import {createClient, SupabaseClient} from "@supabase/supabase-js";

const supabaseUrl = 'https://lleuhzvmmmfuluxbwrdx.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTY0MDk4MjEwNiwiZXhwIjoxOTU2NTU4MTA2fQ.7gNvzb8L_EVobj9Stkhjw_8-viIYB5ZnZTQKsFmeXA0';

export const supabase: SupabaseClient = createClient(
  supabaseUrl,
  supabaseAnonKey
);

const LandingPageImages = [landingPage1, landingPage2, landingPage3, landingPage4, landingPage5, landingPage6, landingPage7];

function importAll(r) {
  return r.keys().map(r);
}

export function useQuery(): URLSearchParams {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useCheckFeature(x: string): boolean {
  const query = useQuery();
  for (const [key, value] of query.entries()) {
    if (key === "_f") {
      for (const feat of value.split(",")) {
        if (feat === x) {
          return true;
        }
      }
    }
  }
  return false;
}

function HeaderText(props) {
  return <Box onClick={props.onClick}>
    <Text p="16px" letterSpacing="0.15em" fontSize={["0.7rem", "lg"]} cursor="pointer">
      {props.children}
    </Text>
  </Box>
}

enum Page {
  Landing,
  About,
  Gallery,
  Newborn,
  Fresh48,
  Family,
  Couples,
  Pricing,
  Contact
}

function dt(str) {
  return new Date((new Date(str)).toISOString().slice(0, -1))
}

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function formatTime(dt, time) {
  return new Date(`${dt.replace(/-/g, "/")} ${time}`).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
}

export default function App() {
  return (
    <ChakraProvider theme={THEME}>
      <Router>
        <_App />
      </Router>
    </ChakraProvider>
  )
}

function _App() {
  const [landingImageIndex, setLandingImageIndex] = React.useState(0);
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [numberPeople, setNumberPeople] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [messageSent, setMessageSent] = React.useState(false);
  const [galleryMenuOpen, setGalleryMenuOpen] = React.useState(false);
  const [overMenu, setOverMenu] = React.useState(false);
  const [fullscreenImage, setFullscreenImage] = React.useState(null);
  const [newbornImages, setNewbornImages] = React.useState([]);
  const [fresh48Images, setFresh48Images] = React.useState([]);
  const [coupleImages, setCoupleImages] = React.useState([]);
  const [familyImages, setFamilyImages] = React.useState([]);
  const [appts, setAppts] = React.useState([]);
  const [apptSlots, setApptSlots] = React.useState([]);
  const [earliestAvailableAppt, setEarliestAvailableAppt] = React.useState(null);
  const [selectedAppt, setSelectedAppt] = React.useState(null);
  const [allowWinterMiniBooking, setAllowWinterMiniBooking] = React.useState(useCheckFeature("winter-mini"));
  const [onLanding, setOnLanding] = React.useState(window.location.pathname === '/');
  const navigate = useNavigate();
  const logoRef = React.useRef();
  const onSchedulePage = window.location.pathname === '/schedule';

  function goto(path) {
    if (path === "/landing") {
      setOnLanding(true)
    } else {
      setOnLanding(false)
    }
    navigate(path)
  }

  React.useEffect(() => {
    supabase.from("appointments").select().filter('name', 'is', 'null').order("date").then(resp => {
      if (resp.error) {
        console.log({error: resp.error})
      } else {
        let appts = resp.data;
        let earliest = appts.length === 0 ? new Date() : new Date(Math.min.apply(null, appts.map(appt => dt(appt.date))));
        setAppts(appts);
        setEarliestAvailableAppt(earliest);
      }
    })
    supabase.from("featureSwitches").select().match({name: 'winterMiniBooking'}).single().then(resp => {
      if (resp.error) {
        console.log({error: resp.error})
      } else {
        setAllowWinterMiniBooking(resp.data.isOn || allowWinterMiniBooking);
      }
    })
  }, [])

  async function handleApptSubmit(e) {
    e.preventDefault();
    let appt = {name, email, numberPeople, comments: message}
    let {error} = await supabase.from("appointments").update(appt).match({id: selectedAppt.id})
    if (error) {
      console.log({error})
    }
    setMessageSent(true);
    let httpRequest = new XMLHttpRequest();
    httpRequest.open('POST', 'https://free-micro-186905.ue.r.appspot.com/contact', true);
    const body = `Session Booking
    Date: ${selectedAppt.date}
    Name: ${name}
    Email: ${email}
    Number of people: ${numberPeople}
    Message: ${message}
    Appointment Time: ${selectedAppt.slot || "N/A"}
    `
    httpRequest.send(body)
  }

  React.useEffect(() => {
    fetch('https://content-storage.googleapis.com/storage/v1/b/sarahquinnphoto_photos/o?prefix=newborn').then(resp => resp.json()).then(data => {
      //@ts-ignore
      if (data && data.items) {
        //@ts-ignore
        setNewbornImages(data.items.slice(1).map(x => x.mediaLink))
      }
    })
  }, [])

  React.useEffect(() => {
    fetch('https://content-storage.googleapis.com/storage/v1/b/sarahquinnphoto_photos/o?prefix=fresh48').then(resp => resp.json()).then(data => {
      //@ts-ignore
      if (data && data.items) {
        //@ts-ignore
        setFresh48Images(data.items.slice(1).map(x => x.mediaLink))
      }
    })
  }, [])

  React.useEffect(() => {
    fetch('https://content-storage.googleapis.com/storage/v1/b/sarahquinnphoto_photos/o?prefix=couple').then(resp => resp.json()).then(data => {
      //@ts-ignore
      if (data && data.items) {
        //@ts-ignore
        setCoupleImages(data.items.slice(1).map(x => x.mediaLink))
      }
    })
  }, [])

  React.useEffect(() => {
    fetch('https://content-storage.googleapis.com/storage/v1/b/sarahquinnphoto_photos/o?prefix=family').then(resp => resp.json()).then(data => {
      //@ts-ignore
      if (data && data.items) {
        //@ts-ignore
        setFamilyImages(data.items.slice(1).map(x => x.mediaLink))
      }
    })
  }, [])

  React.useEffect(() => {
    if (onLanding) {
      window.setTimeout(() => setLandingImageIndex((landingImageIndex + 1) % LandingPageImages.length), 4000)
    }
  }, [onLanding, landingImageIndex])

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <ChakraProvider theme={THEME}>
      <Box h="full" w="full">
        <Grid rowGap="16px" height="100%" templateRows={["2fr 3fr 1fr", "2fr 7fr 1fr"]}>
          <Grid templateColumns={["1fr 1fr 1fr 1fr", "1fr 1fr 2fr 1fr 1fr"]} alignItems="center" justifyItems="center" minHeight={0} paddingY="24px">
            <HeaderText onClick={() => goto("/about")}>ABOUT</HeaderText>
            <Flex alignItems="center" justifyContent="center" height="100%" width="100%" onMouseEnter={() => setGalleryMenuOpen(true)} onMouseLeave={() => !overMenu && setGalleryMenuOpen(false)}>
              <Menu isOpen={isOpen} autoSelect={false}>
                <MenuButton onClick={() => goto("/gallery")} onMouseEnter={onOpen}>
                  <HeaderText>GALLERY</HeaderText>
                </MenuButton>
                <MenuList onMouseLeave={onClose}>
                  <MenuItem onClick={() => {onClose(); goto("/gallery/newborn")}}>Newborn</MenuItem>
                  <MenuItem onClick={() => {onClose(); goto("/gallery/fresh48")}}>Fresh 48</MenuItem>
                  <MenuItem onClick={() => {onClose(); goto("/gallery/family")}}>Family</MenuItem>
                  <MenuItem onClick={() => {onClose(); goto("/gallery/couples")}}>Couples</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Flex ref={logoRef} overflow="hidden" alignSelf="stretch" justifyContent="center" order={[-1, 0]} gridColumn={["1 / 5", "auto"]} minHeight={0}><Image cursor="pointer"  onClick={() => goto("/landing")} maxWidth="70%" minHeight={0} src={black_logo} objectFit="contain" /></Flex>
            <HeaderText onClick={() => goto("/pricing")}>PRICING</HeaderText>
            <HeaderText onClick={() => goto("/contact")}>CONTACT</HeaderText>
          </Grid>
          <Routes>
            <Route path="*" element={
              <Flex overflow="hidden" justifyContent="center">
                <Flex width="90vw" position="relative" justifyContent="center" alignItems="center">
                  {LandingPageImages.map((x, i) => <Image key={i} width="auto" minHeight={0} objectFit="contain" src={x} position="absolute" opacity={i === landingImageIndex ? 1 : 0} transform="scale(1)" transition="opacity 1s ease-in-out"/>)}
                </Flex>
              </Flex>
            } />
            <Route path="/about" element={
              <Flex overflow="hidden" justifyContent="center">
                <Grid p="16px" width="90%" overflow="auto" gap="16px" templateColumns={["1fr", "1fr 1fr"]} justifyContent="center" justifyItems="center">
                  <Image objectFit="contain" src={about1} />
                  <Box>
                    <Text>{aboutMe.first}</Text><br />
                    <Text>{aboutMe.second}</Text><br />
                    <Text>{aboutMe.third}</Text><br />
                    <Text>{aboutMe.fourth}</Text>
                  </Box>
                  <Image objectFit="contain" src={about2} />
                  <Image objectFit="contain" src={about3} />
                  <Image gridColumn={["", "1 / 3"]} objectFit="contain" src={about4} />
                  <Image gridColumn={["", "1 / 3"]} objectFit="contain" src={about5} />
                </Grid>
              </Flex>
            } />
            <Route path="/gallery" element={
              <Flex overflow="hidden" justifyContent="center">
                <Grid p="16px" width="90%" overflow="auto" gap="16px" templateColumns={["1fr", "1fr 1fr"]} justifyContent="center" justifyItems="center">
                  <Stack cursor="pointer" onClick={() => goto("/gallery/newborn")} spacing="8px">
                    <Text>{pricingBlurbs.newborn.title}</Text>
                    <Image objectFit="contain" src={newborn} />
                  </Stack>
                  <Stack cursor="pointer" onClick={() => goto("/gallery/fresh48")} spacing="8px">
                    <Text>{pricingBlurbs.fresh48.title}</Text>
                    <Image objectFit="contain" src={fresh48} />
                  </Stack>
                  <Stack cursor="pointer" onClick={() => goto("/gallery/family")} spacing="8px">
                    <Text>{pricingBlurbs.family.title}</Text>
                    <Image objectFit="contain" src={family} />
                  </Stack>
                  <Stack cursor="pointer" onClick={() => goto("/gallery/couples")} spacing="8px">
                    <Text>{pricingBlurbs.couples.title}</Text>
                    <Image objectFit="contain" src={couples} />
                  </Stack>
                </Grid>
              </Flex>
            } />
            <Route path="/gallery/newborn" element={
              <Flex overflow="hidden" justifyContent="center">
                <Grid p="16px" width="90%" overflow="auto" templateColumns={["1fr", "1fr 1fr 1fr"]} gap="16px" justifyContent="center" justifyItems="center">
                  {newbornImages.map((x, i) => <Image key={i} onClick={() => setFullscreenImage({images: newbornImages, i})} src={x} />)}
                </Grid>
              </Flex>
            } />
            <Route path="/gallery/fresh48" element={
              <Flex overflow="hidden" justifyContent="center">
                <Grid p="16px" width="90%" overflow="auto" templateColumns={["1fr", "1fr 1fr 1fr"]} gap="16px" justifyContent="center" justifyItems="center">
                  {fresh48Images.map((x, i) => <Image key={i} onClick={() => setFullscreenImage({images: fresh48Images, i})} src={x} />)}
                </Grid>
              </Flex>
            } />
            <Route path="/gallery/family" element={
              <Flex overflow="hidden" justifyContent="center">
                <Grid p="16px" width="90%" overflow="auto" templateColumns={["1fr", "1fr 1fr 1fr"]} gap="16px" justifyContent="center" justifyItems="center">
                  {familyImages.map((x, i) => <Image key={i} onClick={() => setFullscreenImage({images: fresh48Images, i})} src={x} />)}
                </Grid>
              </Flex>
            } />
            <Route path="/gallery/couples" element={
              <Flex overflow="hidden" justifyContent="center">
                <Grid p="16px" width="90%" overflow="auto" templateColumns={["1fr", "1fr 1fr 1fr"]} gap="16px" justifyContent="center" justifyItems="center">
                  {coupleImages.map((x, i) => <Image key={i} onClick={() => setFullscreenImage({images: fresh48Images, i})} src={x} />)}
                </Grid>
              </Flex>
            } />
            <Route path="/pricing" element={
              <Flex overflow="hidden" justifyContent="center">
                <Grid p="16px" width="90%" overflow="auto" templateColumns={["1fr", "1fr 1fr"]} gap="16px" justifyContent="center" justifyItems="center">
                  <Stack spacing="8px">
                    <Image src={newborn} />
                    <Text fontSize="lg">{pricingBlurbs.newborn.title}</Text>
                    <Text>{pricingBlurbs.newborn.description}</Text>
                    <List styleType="disc">
                      {pricingBlurbs.newborn.bullets.map((x, i) => <ListItem key={i}>{x}</ListItem>)}
                    </List>
                  </Stack>
                  <Stack spacing="8px">
                    <Image objectFit="contain" src={fresh48} />
                    <Text fontSize="lg">{pricingBlurbs.fresh48.title}</Text>
                    <Text>{pricingBlurbs.fresh48.description}</Text>
                    <List styleType="disc">
                      {pricingBlurbs.fresh48.bullets.map((x, i) => <ListItem key={i}>{x}</ListItem>)}
                    </List>
                  </Stack>
                  <Stack spacing="8px">
                    <Image objectFit="contain" src={family} />
                    <Text fontSize="lg">{pricingBlurbs.family.title}</Text>
                    <Text>{pricingBlurbs.family.description}</Text>
                    <List styleType="disc">
                      {pricingBlurbs.family.bullets.map((x, i) => <ListItem key={i}>{x}</ListItem>)}
                    </List>
                  </Stack>
                  <Stack spacing="8px">
                    <Image objectFit="contain" src={couples} />
                    <Text fontSize="lg">{pricingBlurbs.couples.title}</Text>
                    <Text>{pricingBlurbs.couples.description}</Text>
                    <List styleType="disc">
                      {pricingBlurbs.couples.bullets.map((x, i) => <ListItem key={i}>{x}</ListItem>)}
                    </List>
                  </Stack>
                  <Box pt="48px" gridColumn={["", "1 / 3"]}>
                    <Text fontSize="11px">≫ {pricingBlurbs.otherinfo[0]}</Text>
                    <br />
                    <Text fontSize="11px">≫ {pricingBlurbs.otherinfo[1]}</Text>
                  </Box>
                </Grid>
              </Flex>
            } />
            <Route path="/contact" element={
              <Flex overflow="hidden" justifyContent="center">
                <Grid p="16px" width="90%" overflow="auto" templateColumns={["1fr", "3fr 2fr"]} gap="16px" justifyContent="center" justifyItems="center">
                  <Image objectFit="contain" src={contactPicture} />
                  {!messageSent ? (
                    <Stack width="80%" spacing="16px">
                      <Input size="lg" variant="flushed" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
                      <Input size="lg" variant="flushed" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                      <Textarea size="lg" variant="flushed" placeholder="Message" value={message} onChange={e => setMessage(e.target.value)} />
                      <ButtonX text="Submit" onClick={() => {
                        let httpRequest = new XMLHttpRequest();
                        httpRequest.open('POST', 'https://free-micro-186905.ue.r.appspot.com/contact', true);
                        const body = "Name: " + name + "\nEmail: " + email + "\n\n" + message;
                        httpRequest.send(body)
                        setMessageSent(true)
                      }}/>
                    </Stack>
                  ) : (
                    <Text>Thanks for reaching out! I'll get back to you as soon as possible!</Text>
                  )}
                </Grid>
              </Flex>
            } />
            <Route path="/schedule" element={
              <Flex flex={1} overflow="hidden" justifyContent="center">
                <Flex direction={{base: 'column', md: 'row'}} gap="4" overflow="auto" pb="4">
                  <Flex justifyContent="center"><Text display={{base: 'block', md: 'none'}} fontSize="xs">Scroll down to see booking details</Text></Flex>
                  <Flex px="16" flex={1} justifyContent="center">
                    <VStack spacing="6">
                      <Image px="1" minW="sm" maxH="sm" objectFit="contain" src={schedulePic1} />
                      <Image px="1" minW="sm" maxH="sm" objectFit="contain" src={schedulePic2} />
                    </VStack>
                  </Flex>
                  <Box flex={1}>
                    {messageSent ? (
                      <Flex justifyContent="center">
                        <Text p="4">Thanks for your interest in booking! I'll get back to you as soon as possible!</Text>
                      </Flex>
                    ) : (
                      <VStack spacing="8">
                        <Text p="4" maxW="600px" fontSize="sm" justifyContent="center">
                        Holiday mini sessions are here! I have very limited spots available on Wednesday, November 23, 2022. Each mini session is 15 mins and costs $115. You will receive a gallery of all the best images from our session (you can expect about 20 images). After booking a session here, you are required to pay the full $115 in order to reserve your session. If the fee is not paid, your slot will be reopened. This fee is non-refundable. However, if you need to cancel and a replacement can be found, your fee will be refunded. Each session comes with an online gallery of high-resolution edited images that can be downloaded, printed, shared, and enjoyed!
                        </Text>
                        <Calendar
                          value={selectedAppt ? dt(selectedAppt.date) : earliestAvailableAppt}
                          onChange={value => {
                            let apps = appts.filter(x => dt(x.date).getTime() === value.getTime());
                            console.log({apps})
                            if (apps.length > 1 || apps[0].slot) {
                              setApptSlots(apps);
                            } else {
                              setSelectedAppt(apps[0]);
                            }
                          }}
                          tileDisabled={({date, view }) => view === 'month' && !appts.find(x => dt(x.date).getTime() === date.getTime())}
                        />
                        {(!!selectedAppt || apptSlots.length > 0) && (
                          <Box width="sm" px="2">
                            <form onSubmit={handleApptSubmit}>
                              <VStack spacing="4">
                                {apptSlots.length > 0 && (
                                  <FormControl isRequired>
                                    <Select placeholder="Select time" variant='flushed' value={selectedAppt?.slot || ""} onChange={e => {
                                        console.log({val: e.target.value})
                                        setSelectedAppt(apptSlots.find(x => x.slot === e.target.value));
                                      }}>
                                      {apptSlots.map(x => <option key={x.id} value={x.slot}>{x.slot}</option>)}
                                    </Select>
                                  </FormControl>
                                )}
                                <FormControl isRequired>
                                  <Input size="lg" variant="flushed" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
                                </FormControl>
                                <FormControl isRequired>
                                  <Input size="lg" variant="flushed" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                                </FormControl>
                                <FormControl>
                                  <Input size="lg" variant="flushed" placeholder="Number of people" value={numberPeople} onChange={e => setNumberPeople(e.target.value)} />
                                </FormControl>
                                <Textarea size="lg" variant="flushed" placeholder="Message" value={message} onChange={e => setMessage(e.target.value)} />
                                <Button colorScheme="purple" type="submit">Book Session</Button>
                              </VStack>
                            </form>
                          </Box>
                        )}
                      </VStack>
                    )}
                  </Box>
                </Flex>
              </Flex>
            } />
          </Routes>
          <Outlet />
          <Grid borderTop="1px solid #f8f8fa" p="16px" pt="2" templateColumns={["1fr", "1fr 2fr 1fr"]} gap="16px" alignItems="center" justifyItems="center">
            <Text fontSize="sm">Copyright 2022 Sarah Quinn Photo</Text>
            <Stack direction={{base: "column", md: "row"}} spacing="10px" justifyContent="center" alignItems="center">
              <ButtonX text="BOOK NOW" width="200px" onClick={() => goto("/contact")}/>
            </Stack>
            <Flex width="158px" direction="row" justifyContent="space-around">
              <a href="https://www.instagram.com/sarahquinnphotography/" target="_blank">
                <Image height="20px" width="20px" className="InstagramIcon" src={insta} />
              </a>
              <a href="https://www.facebook.com/sarahquinnphoto/" target="_blank">
                <Image height="20px" width="20px" className="InstagramIcon" src={fb} />
              </a>
            </Flex>
          </Grid>
          <GalleryImage src={fullscreenImage} onClose={() => setFullscreenImage(null)} />
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

function ButtonX(props: {text: string, onClick: any, width?: string}) {
  return <Button onClick={props.onClick} width={props.width} bg="#BA9B82" color="white" letterSpacing="0.15em" p={1} fontSize="md" _hover={{}}>{props.text}</Button>
}

function GalleryImage(props: {onClose: () => void, src: {images: string[], i: number}}) {
  const [index, setIndex] = React.useState(null)
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (props.src) {
      setIndex(props.src.i)
    }
  }, [props.src])
  
  useEventListener('swipeleft', () => {
    setIndex(index === props.src.images.length ? index : index + 1)
  }, ref.current)

  useEventListener('swiperight', () => {
    setIndex(index === 0 ? 0 : index - 1)
  }, ref.current)

  return <Modal size="90%" id="modal" isOpen={props.src !== null} onClose={props.onClose}>
    <ModalOverlay />
    <div ref={ref}>
      <ModalContent border="none" onClick={props.onClose} bg="transparent" onKeyDown={e => {
        if (e.keyCode === 37) {
          setIndex(index === 0 ? 0 : index - 1)
        } else if (e.keyCode === 39) {
          setIndex(index === props.src.images.length - 1 ? index : index + 1)
        }
      }}>
        <Image height="90vh" width="auto" objectFit="contain" src={props.src && props.src.images[index]} />
      </ModalContent>
    </div>
  </Modal>
}

// Hook
function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = React.useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(
    () => {
      // Make sure element supports addEventListener
      // On 
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      //@ts-ignore
      const eventListener = event => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
};
